import { graphql } from 'gatsby'
import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class ContactPage extends Component {
  render() {
    let contactData = this.props.data.allContactJson.nodes[0]
    return (
      <Layout page={"contact"}>
        <h1 className="title">{contactData.title}</h1>
        {/*
        <div className="description">
          {contactData.text}
        </div>
        */}
        <div className="contact-grid-container">
          <div className="contact-grid-item">
            <img
              src={contactData.email_icon}
              alt="Email"
              className="contact-icon"
            ></img>
          </div>
          <div className="contact-grid-item">
            <img
              src={contactData.phone_icon}
              alt="Phone"
              className="contact-icon"
            ></img>
          </div>
          {/*
          <div className="contact-grid-item">
            <img
              src={contactData.twitter_icon}
              alt="Twitter"
              className="contact-icon"
            ></img>
          </div>
          */}
          <div className="contact-grid-item">{contactData.email}</div>
          <div className="contact-grid-item">{contactData.phone}</div>
          {/*<div className="contact-grid-item">{contactData.twitter}</div>*/}
        </div>
        <div class="page-content">
          <h2 class="center-text">Encontre-nos aqui</h2>
        </div>
        <div class="map">
          <div class="page-line">
            <div class="gmap_canvas">
              <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Av.%20de%20Sousa%20Cruz%20671,%204780-365%20Santo%20Tirso&t=&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              <style>{".mapouter{position:relative;text-align:right;height:500px;width:600px;"}</style>
              <style>{".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}"}</style>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default ContactPage

export const query = graphql`
  query {
    allContactJson {
      nodes {
        id
        title
        description
        text
        email
        phone
        twitter
        email_icon
        phone_icon
        twitter_icon
      }
    }
  }
`
